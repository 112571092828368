import React from "react";
 import MainNav from "../components/MainNav";

const About = () => {
  return (
    <div>
      <MainNav />
      <h1>ABOUT</h1>
    </div>
  );
};

export default About;
