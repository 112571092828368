import React from "react";
import MainNav from "../components/MainNav";

const Resume = () => {
  return (
    <div>
      <MainNav/>
      <h1>RESUME</h1>
    </div>
  );
};

export default Resume;
