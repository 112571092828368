import React from "react";
import MainNav from "../components/MainNav";

const Home = () => {
  return (
    <div>
        <MainNav/>
      <h1>HOME</h1>
    </div>
  );
};

export default Home;
